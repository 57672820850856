import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Card, Col, Dropdown, DropdownMenu, Row, Table, Button, Modal, Form, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faBell, faBookReader, faCalendar, faChalkboardTeacher, faCheck, faClipboardUser, faDownload, faEllipsisV, faEye, faFileCircleExclamation, faFileExport, faHandHoldingHeart, faHandsHelping, faPen, faPeopleCarry, faPlus, faPrint, faTimes, faTrash, faUniversalAccess, faUser } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { GET, POST } from '../../../controllers/endpoints.controller'
import moment from 'moment'
import Loading from '../../general-components/Loading'
import InfoField from '../../general-components/InfoField'
import { downloadFile } from '../../../tools/tools'
import NotFound from '../../general-components/NotFound'
import PDFViewer from 'mgr-pdf-viewer-react/dist/mgr-pdf-viewer-react'

function Reports() {
    const PAGE_TITLE = "Boletín (entregable)";
    const PAGE_ICON = faChalkboardTeacher;

    const [isTableLoaded, setIsTableLoaded] = useState(false);
    const [myRole, setMyRole] = useState(getUserRole());

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getClassrooms();
    }, []);

    const [showLoading, setShowLoading] = React.useState(false);
    const handleCloseLoadingModal = () => { setShowLoading(false); };
    const handleShowLoadingModal = () => {
        setShowLoading(true);
    };

    //DropDown Course
    const [selectedClassroomOption, setSelectedClassroomOption] = useState('Curso');
    const [selectedClassroomId, setSelectedClassroomId] = useState(null);

    const handleSelectClassroom = (eventKey) => {
        setIsTableLoaded(false)
        setSelectedClassroomOption(`${classrooms[eventKey].degree}º ${classrooms[eventKey].division}`);
        setSelectedClassroomId(classrooms[eventKey].id);
        setSelectedPeriodGeneralOption("Período");
        setSelectedPeriodGeneralId(null);
    };

    const [classrooms, setClassrooms] = useState([]);
    const [students, setStudents] = useState([]);

    const getClassrooms = async function () {
        if (getUserRole() == "Docente Jardín" || getUserRole() == "Docente Primaria" || getUserRole() == "Docente Secundaria") {
            const req = await GET(`/classes/classrooms/byTeacherId`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                if (res.length > 0) {
                    setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                    setSelectedClassroomId(res[0].id)
                }
                getPeriods(res[0].id)
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await GET(`/classes/classrooms/bySchoolYear/${moment().format('YYYY')}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                if (res.length > 0) {
                    setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                    setSelectedClassroomId(res[0].id)
                }
                getPeriods(res[0].id)
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const getStudentsWithReportFiles = async function (classroomId, periodId) {
        setIsTableLoaded(false);

        const req = await GET(`/grades/reportFiles/byClassroomIdAndPeriodId/${classroomId}/${periodId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setStudents(res);
            setIsTableLoaded(true);
            checkReportStatus(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [periods, setPeriods] = useState([]);
    const getPeriods = async function (cid) {
        const req = await GET(`/grades/periods/byClassroomId/noGrouped/${cid}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPeriods(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [selectedPeriodGeneralOption, setSelectedPeriodGeneralOption] = useState('Período');
    const [selectedPeriodGeneralId, setSelectedPeriodGeneralId] = useState(null);

    const handleSelectPeriodGeneral = (eventKey) => {
        setSelectedPeriodGeneralOption(periods[eventKey].name);
        setSelectedPeriodGeneralId(periods[eventKey].id);
        getStudentsWithReportFiles(selectedClassroomId, periods[eventKey].id)
    };

    const [areAllReportsFinished, setAreAllReportsFinished] = useState(false);

    const [showConfirmcreateReportModal, setShowConfirmcreateReportModal] = useState(false);
    const handleCloseConfirmcreateReportModal = () => setShowConfirmcreateReportModal(false);
    const handleShowConfirmcreateReportModal = () => {
        setShowConfirmcreateReportModal(true);
    };

    const postCreateReports = async function () {
        const body = {
            classroomId: selectedClassroomId,
            periodId: selectedPeriodGeneralId
        }
        console.log(body)
        const req = await POST(`/grades/report/byClassroom`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseConfirmcreateReportModal();
            getStudentsWithReportFiles(selectedClassroomId, selectedPeriodGeneralId)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postSendNotifications = async function () {
        const body = {
            classroomId: selectedClassroomId,
            periodId: selectedPeriodGeneralId
        }
        console.log(body)
        const req = await POST(`/grades/reportFiles/send`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getStudentsWithReportFiles(selectedClassroomId, selectedPeriodGeneralId)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const checkReportStatus = (reports) => {
        let areAllFinished = true;
        for (let i = 0; i < reports.length; i++) {
            if (reports[i].isPending == true || reports[i].hasReportFile == false) {
                areAllFinished = false;
                break;
            }
        }
        setAreAllReportsFinished(areAllFinished);
    }

    const downloadAllReports = async function () {
        handleShowLoadingModal();
        const req = await GET(`/grades/reportFiles/zip/byClassroomIdAndPeriodId/${selectedClassroomId}/${selectedPeriodGeneralId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            const linkSource = res.file;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = `Boletines ${selectedClassroomOption}.${res.extension}`;
            downloadLink.click();
            handleCloseLoadingModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const rolesToSeePdf = ["Rectorado Secundaria", "Dirección Primaria", "Director Primaria", "Dirección Jardín"]

    const [showViewFileModal, setShowViewFileModal] = React.useState(false);
    const [actualViewFile, setActualViewFile] = React.useState(null);
    const [actualViewFileName, setActualViewFileName] = React.useState("");
    const [actualViewFileExtension, setActualViewFileExtension] = React.useState(null);
    const handleCloseViewFileModal = () => {
        setShowViewFileModal(false);
        setActualViewFile(null);
    };
    const handleShowViewFileModal = () => {
        setShowViewFileModal(true);
    };

    const [actualViewIndex, setActualViewIndex] = useState(null)
    const viewReport = async (report, index) => {
        handleShowViewFileModal();

        const req = await GET(`/files/byIdNoHeader/${report.reportFileFileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res);
            if (res.extension.includes("pdf") || res.extension.includes("png") || res.extension.includes("jpg") || res.extension.includes("jpeg")) {
                setActualViewFile(res.file);
                setActualViewFileName(res.name);
                setActualViewFileExtension(res.extension)
                setActualViewIndex(index)
            } else {
                //downloadFile(fileId, `Test.${res.extension}`);
            }

        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const handleNextStudent = (index) => {
        console.log(students[index])
        viewReport(students[index], index);
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='fitContentWidth'>
                        <div className='align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-100">
                                    {selectedClassroomOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassroomOption}>
                                    {classrooms.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectClassroom(i)}>{opt.degree}º {opt.division}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-175">
                                    {selectedPeriodGeneralOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedPeriodGeneralOption}>
                                    {periods.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectPeriodGeneral(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title='Generar boletines' variant='primary' disabled={selectedPeriodGeneralId == null} func={handleShowConfirmcreateReportModal} />


                        <Dropdown className='ms-2'  >
                            <Dropdown.Toggle variant="primary" id="dropdown-basic" >
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon className='me-2' icon={faEllipsisV} />Opciones</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={downloadAllReports} disabled={!areAllReportsFinished}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar todos</Dropdown.Item>
                                <Dropdown.Item onClick={postSendNotifications} disabled={!areAllReportsFinished}><FontAwesomeIcon icon={faBell} style={{ marginRight: 11, marginLeft: 3 }} />Notificar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>

                <Card className='cards mt-3'>
                    {!isTableLoaded ?
                        <div className='justify-content-center'>
                            {selectedPeriodGeneralId != null && selectedClassroomId != null ?
                                <Loading animation={'border'} classExtra='loading-100' />
                                :
                                <NotFound classExtra="loading-100" iconFa={faFileCircleExclamation} text='Seleccioná un curso y un período' />
                            }
                        </div> : <><div>
                            <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>

                                <Col md={5} className='align-center-vertically-v2'>
                                    <div>
                                        Estudiante
                                    </div>
                                </Col>
                                <Col md={2} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'center' }}>
                                        Estado
                                    </div>
                                </Col>
                                <Col md={2} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'center' }}>
                                        Notificado
                                    </div>
                                </Col>
                                <Col md={2} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'center' }}>
                                        Fecha
                                    </div>
                                </Col>
                                <Col md={1} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'center' }}>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                            {students.map((r, i) => (
                                <div key={i}>
                                    <div className='d-none d-lg-block'>
                                        <Row className='mb-1 ps-2 pe-2 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                            <Col md={5} className='align-center-vertically-v2'>
                                                <div >
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                                        <div className='fitContentWidth p-1'>
                                                            <div className='studentImg circle-container' style={{ borderColor: 'white', background: 'white' }}>
                                                                {r.avatarFile ? <img className='' src={r.avatarFile} alt={r.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                                            </div>
                                                        </div>
                                                        <span className='ms-1' style={{ textAlign: 'left' }}>{r.surname && r.surname.toUpperCase()}, {r.name}</span>
                                                    </div>
                                                </div>

                                            </Col>

                                            <Col md={2} className='align-center-vertically' style={{ justifyContent: 'center' }}>
                                                <div style={{ textAlign: 'center' }}>
                                                    {r.hasReportFile ?
                                                        <>
                                                            {r.isPending ?
                                                                <Badge bg='warning' className='statusBadge fs-14 wi-100'>En proceso</Badge>
                                                                :
                                                                <Badge bg='success' className='statusBadge fs-14 wi-100'>Listo</Badge>
                                                            }
                                                        </>
                                                        :
                                                        <Badge bg='secondary' className='statusBadge fs-14 wi-100'>Sin generar</Badge>
                                                    }
                                                </div>
                                            </Col>

                                            <Col md={2} className='align-center-vertically' style={{ justifyContent: 'center' }}>
                                                <div style={{ textAlign: 'center' }}>
                                                    {r.isNotified ?
                                                        <>
                                                            <Badge bg='success' className='statusBadge fs-14 wi-100'>Notificado</Badge>
                                                        </>
                                                        :
                                                        <Badge bg='secondary' className='statusBadge fs-14 wi-100'>Sin notificar</Badge>
                                                    }
                                                </div>
                                            </Col>

                                            <Col md={2} className='align-center-vertically' style={{ justifyContent: 'center' }}>
                                                <div style={{ textAlign: 'center' }}>
                                                    {r.hasReportFile ?
                                                        <>
                                                            {moment(r.date).format("DD/MM HH:MM")}
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                            </Col>

                                            <Col md={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                {(r.hasReportFile && !r.isPending) ?
                                                    <Dropdown className='ms-2'>
                                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => downloadFile(r.reportFileFileId, `Boletín ${selectedPeriodGeneralOption} - ${selectedClassroomOption} - ${r.surname ? r.surname.toUpperCase() : ""}, ${r.name}.pdf`)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => viewReport(r, i)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    :
                                                    <></>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ))}
                        </>}
                </Card>
            </div>

            <Modal show={showConfirmcreateReportModal} onHide={handleCloseConfirmcreateReportModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faFileExport} style={{ minWidht: '20px' }} /><Modal.Title>Generar boletines</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-4 mb-3'>
                    <span className=''>¿Deseas generar los boletines de "{selectedClassroomOption}" para el "{selectedPeriodGeneralOption}"?</span>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmcreateReportModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14 me-2' onClick={postCreateReports}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showLoading} centered backdrop="static" keyboard={false} onHide={handleCloseLoadingModal}>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <Spinner className='mt-3' variant='primary' animation="border" />
                    <p className='mt-1 mb-3'>Procesando...</p>
                </Modal.Body>
            </Modal>

            <Modal size='xl' show={showViewFileModal} onHide={handleCloseViewFileModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{actualViewFileName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {actualViewFile ?
                        <PDFViewer document={{
                            base64: actualViewFile
                        }} />
                        :
                        <div className='w-100 justify-content-center' style={{ textAlign: 'center', marginTop: 200, marginBottom: 200 }}>
                            <Loading animation={'border'} />
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="secondary" onClick={() => handleNextStudent(actualViewIndex - 1)} disabled={actualViewIndex == 0}>
                        <FontAwesomeIcon className='mr-10' icon={faArrowLeft} />Anterior
                    </Button>
                    <Button variant="secondary" onClick={() => handleNextStudent(actualViewIndex + 1)} disabled={actualViewIndex == students.length - 1 || students[actualViewIndex + 1] && !students[actualViewIndex + 1].hasReportFile}>
                        <FontAwesomeIcon className='mr-10' icon={faArrowRight} />Siguiente
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default Reports