import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Card, Col, Dropdown, DropdownMenu, Row, Table, Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookReader, faChalkboardTeacher, faChartBar, faCheck, faClipboardUser, faEllipsisV, faEye, faFileCircleCheck, faFileExport, faHandHoldingHeart, faHandsHelping, faPen, faPeopleCarry, faPrint, faTimes, faTrash, faUniversalAccess, faUser } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { GET, PUT } from '../../../controllers/endpoints.controller'
import moment from 'moment'
import Loading from '../../general-components/Loading'
import InfoField from '../../general-components/InfoField'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function Indicators() {
    const PAGE_TITLE = "Indicadores";
    const PAGE_ICON = faChartBar;

    const [isTableLoaded, setIsTableLoaded] = useState(false);

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getAllIndicators();
    }, []);

    const [indicators, setIndicators] = useState({
        REGI: [],
        GRAD: []
    });

    const getAllIndicators = async function () {
        const req = await GET(`/indicators/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            getIndicatorsByMicroserviceAndSlug(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getIndicatorsByMicroserviceAndSlug = async function (indicators) {
        for (let ind of indicators) {
            const req = await GET(`/indicators/byMicroservice&Slug/${ind.microservice}/${ind.slug}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setIndicators(prev => ({
                    ...prev,
                    [ind.microservice]: [...prev[ind.microservice], res]
                }));
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    function RECHART_StackedBarChart({ ind }) {
        return <>
            <ResponsiveContainer width="100%" height={400} className={'mt-4'}>
                <BarChart
                    width={500}
                    height={300}
                    data={ind.data}
                    margin={{
                        top: 0,
                        right: 10,
                        left: -30,
                        bottom: 0,
                    }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={ind.config.xDataKey} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {ind.config.barsDataKey.map((bdk, j) => (
                        <Bar dataKey={bdk.dataKey} stackId="a" fill={bdk.fill} />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </>
    }

    function RECHART_SimpleBarChart({ ind }) {
        return <>
            <ResponsiveContainer width="100%" height={400} className={'mt-4'}>
                <BarChart
                    width={500}
                    height={300}
                    data={ind.data}
                    margin={{
                        top: 0,
                        right: 10,
                        left: -30,
                        bottom: 0,
                    }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={ind.config.xDataKey} />
                    {ind.config.yDomain ?
                        <YAxis domain={ind.config.yDomain} />
                        :
                        <YAxis />
                    }
                    <Tooltip />
                    <Legend />
                    {ind.config.barsDataKey.map((bdk, j) => (
                        <Bar dataKey={bdk.dataKey} fill={bdk.fill} />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </>
    }


    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>

                <Row className='mt-4'>
                    {indicators.REGI.map((ind, i) => (
                        <Col md={ind.cols}>
                            <Card className='cards mt-2'>
                                {ind.type == "RECHART_StackedBarChart" ?
                                    <>
                                        <p>
                                            <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faFileCircleCheck}></FontAwesomeIcon></span>
                                            <span className='ms-2'>Inscripciones - {ind.description}</span>
                                        </p>
                                        <RECHART_StackedBarChart ind={ind} />
                                    </>
                                    : <></>
                                }
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Row>
                    {indicators.GRAD.map((ind, i) => (
                        <Col md={ind.cols}>
                            <Card className='cards mt-2'>
                                {ind.type == "RECHART_SimpleBarChart" ?
                                    <>
                                        <p>
                                            <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faFileCircleCheck}></FontAwesomeIcon></span>
                                            <span className='ms-2'>Calificaciones - {ind.description}</span>
                                        </p>
                                        <RECHART_SimpleBarChart ind={ind} />
                                    </>
                                    : <></>
                                }
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    )
}

export default Indicators