import React, { Fragment, useEffect, useState } from 'react'
import { Col, Dropdown, Row, Card, Modal, ToggleButton, Badge, Button, Spinner } from 'react-bootstrap';
import Navbar from '../../navbar/Navbar';
import { faClipboardUser, faChalkboardTeacher, faPen, faCaretUp, faCaretDown, faCalendar, faCheck, faArrowLeft, faPlus, faTimes, faFileCircleExclamation, faUserGroup, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { useLocation } from "react-router-dom";
import { GET, POST } from '../../../controllers/endpoints.controller';
import { getFamilyMemberId, getFamilyMemberToken, getUserRole, getUserToken, logout } from '../../../controllers/user.controller';
import Loading from '../../general-components/Loading';
import { downloadFile, formatNameV2, getMonthsActualYear } from '../../../tools/tools';
import NotFound from '../../general-components/NotFound';
import InfoField from '../../general-components/InfoField';
registerLocale('es', es)


function AssistanceStudent() {
    const PAGE_TITLE = "Asistencia - Vista estudiante";
    const PAGE_ICON = faClipboardUser;
    const PREVIOUS_PAGE = "";
    const PREVIOUS_URL = "";

    const months = getMonthsActualYear(false);

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;

        setupMonth(moment().daysInMonth());
        setSelectedDateOption(months[moment().month()].name);
        setSelectedDateNumber(months[moment().month()].num);
        getAssistance(getFamilyMemberId(), months[moment().month()].num);
        getAssistanceValues()
    }, []);

    const [myRole, setMyRole] = useState(getUserRole());
    const [userToken, setUserToken] = useState(myRole.includes("Familia") || myRole.includes("Padre-Madre-Tutor") ? getFamilyMemberToken() : getUserToken());

    const [classrooms, setClassrooms] = useState([]);
    const [classes, setClasses] = useState([]);
    const [assistanceValuesText, setAssistanceValuesText] = useState("");
    const [assistanceValues, setAssistanceValues] = useState([]);
    const [students, setStudents] = useState([]);
    const [assistance, setAssistance] = useState([]);
    const [isTableLoaded, setIsTableLoaded] = useState(false);
    const [isTableTakeAssistanceLoaded, setIsTableTakeAssistanceLoaded] = useState(false);

    const [numberDay, setNumberDay] = useState([]);

    const setupMonth = (qty) => {
        const days = [];

        for (let i = 1; i <= qty; i++) {
            days.push(i);
        }

        setNumberDay(days);
    }

    const getParams = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const [selectedDateOption, setSelectedDateOption] = useState('Fecha');
    const [selectedDateNumber, setSelectedDateNumber] = useState(null);

    const handleSelectDate = (i) => {
        setIsTableLoaded(false);
        setSelectedDateOption(months[i].name);
        setSelectedDateNumber(months[i].num);
        setupMonth(months[i].qtyDays);
        getAssistance(getFamilyMemberId(), months[i].num);
    };

    const dataCard = [{
        title: 'Asistencia del año',
        value: '89',
        subtitle: '',
        compare: '-'
    }, {
        title: 'Faltas justificadas',
        value: '58',
        subtitle: '',
        compare: '-'
    },
    {
        title: 'Asistencia del mes',
        value: '91',
        subtitle: '',
        compare: '+'
    }, {
        title: 'Asistencia del día',
        value: '98',
        subtitle: '',
        compare: '+'
    }]

    const stadisticCard = (title, value, subtitle, compare, i) => (
        <Col md={3} key={i} className='ps-0'>
            <Card className='staticCards cards'>
                <p className='m-0'>{title}</p>
                <div className='wrapCont flexEnd'>
                    <p className='m-0 fw-700 fs-38'>{value}%</p>
                    {compare == '+' ? <FontAwesomeIcon className='arrowStatics' style={{ color: '#73D64D' }} icon={faCaretUp} /> : <FontAwesomeIcon className='arrowStatics' style={{ color: '#EA4335' }} icon={faCaretDown} />}
                    <span className='fs-8' style={{ padding: '0px 5px 10px' }}>{subtitle}</span>
                </div>
            </Card>
        </Col>)


    const getAssistance = async function (userId, month) {
        setIsTableLoaded(false);

        const req = await GET(`/assistance/classes/byUserIdAndMonth/${userId}/${month}`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log("Assistance: ")
            console.log(res)
            setAssistance(res)
            setIsTableLoaded(true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getAssistanceValues = async function () {
        const req = await GET(`/assistance/values/byLevelId`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log("Asisstance values")
            console.log(res)
            let txt = "";
            for (let i = 0; i < res.length; i++) {
                if (i == (res.length - 1)) {
                    txt += `${res[i].shortName}: ${res[i].name}`
                } else {
                    txt += `${res[i].shortName}: ${res[i].name} | `
                }
            }
            txt += " | F: Feriado";
            setAssistanceValuesText(txt);
            setAssistanceValues(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    function formatHour(hour) {
        if (hour.length == 8) {
            const combined = moment(`${moment().format('YYYY-MM-DD')}T${hour}`, 'YYYY-MM-DDTHH:mm:ss').toDate();
            combined.setHours(combined.getHours() - 3);
            return combined; 
         }
         return hour;
    }




    const [selectedDateDay, setSelectedDateDay] = useState(new Date());


    const studentRowAssist = (sa, i) => {

        let stdDays = []

        for (let d of sa.dates) {
            stdDays.push(
                <td className='pb-0 pt-1 ps-0 pe-0'>
                    <Row className='tableRows'>
                        <Col className='align-center-vertically-v2 ps-1 pe-1'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Badge onClick={() => { if (d.color !== "#6C757D" && d.shortName !== "ND" && d.shortName != 'N/A') { handleShowAssistanceDetail(sa, d); } }} bg='' className='assistanceBadge fs-14' style={{ height: 36, width: 36, background: `${d.color}`, color: `${d.color != '#FFFFFF' ? 'white' : 'black'}`, cursor: d.color != "#6C757D" && d.shortName != "ND" ? 'pointer' : 'auto' }}>{d.shortName != 'ND' ? d.shortName : '-'}</Badge>
                            </div>
                        </Col>
                    </Row>
                </td>
            )
        }

        return (<tr key={i} className=''>

            <td className='sticky-column pb-0 pt-1 ps-0 pe-0'>
                <Row style={{ borderRadius: '8px 0 0 8px' }} className='tableRows'>
                    <Col className='align-center-vertically-v2'>
                        <div style={{ textAlign: 'left', width: 200 }} className='d-none d-lg-block'>
                            {sa.classId == null ? 'General' : sa.name}
                        </div>
                        <div style={{ textAlign: 'left', width: 90 }} className='d-block d-lg-none fs-12 '>
                            <div className='textDots twoLinesText' style={{ textAlign: 'left', width: 90 }} >
                                {sa.classId == null ? 'General' : sa.name}
                            </div>
                        </div>
                    </Col>
                </Row>
            </td>

            {stdDays.map((s) => (s))}

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRowsDarkLeft'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats.assist.total}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.faults.justified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.faults.unjustified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.faults.noComputable : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRowsDarkLeft'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.last.total : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.last.justified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRowsDarkLeft'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.total : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.totalJustified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.totalUnjustified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

        </tr>)
    }
    const [selectedStudent, setSelectedStudent] = useState([]);
    const handleSelectStudent = (opt) => {
        setSelectedStudent(opt);
    };

    const getChosenNameValue = (assistanceValue) => {
        let value = false;
        let i = 0;

        while (i < assistanceValues.length) {
            if (assistanceValue == assistanceValues[i].shortName) {
                value = assistanceValues[i].name;
                break;
            }
            i++;
        }

        return value;
    }

    const [showAssistanceDetail, setShowAssistanceDetail] = useState(false);
    const [studentAssistanceDetailModal, setStudentAssistanceDetailModal] = useState(null)
    const handleShowAssistanceDetail = (sa, d) => {
        setStudentAssistanceDetailModal({
            student: sa,
            date: d,
            AssistanceName: getChosenNameValue(d.shortName)
        });

        setShowAssistanceDetail(true)
    };

    const handleCloseModalAssistanceDetail = () => {
        setShowAssistanceDetail(false);
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <div className='d-block d-lg-none' style={{ height: '70px' }}> </div>

                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <div className='d-block d-lg-none mt-3'></div>
                <div className='d-none d-lg-block mt-4'></div>
                <Row className=''>


                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton d-none d-lg-block'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-150">
                                    {selectedDateOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedDateOption}>
                                    {months.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectDate(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown className='dropdownButton d-block d-lg-none'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-250">
                                    {selectedDateOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedDateOption}>
                                    {months.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectDate(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>


                <Row>
                    <p className='fs-11 mb-0 mt-3'>{assistanceValuesText}</p>

                </Row>

                <Card className='mobile-cards cards mt-2 h-100-3'>
                    {!isTableLoaded ?
                        <div className='justify-content-center'>
                            {selectedDateDay != null ?
                                <Loading animation={'border'} classExtra='loading-100' />
                                :
                                <NotFound classExtra="mt-5" iconFa={faFileCircleExclamation} text='Seleccioná un mes' />
                            }
                        </div> :
                        <div className='slideTable fs-14 ' style={{ borderRadius: '10px' }}>
                            <table className='br-15 table m-0' style={{ borderColor: 'white' }}>
                                <thead className='sticky-row headerBlue' style={{ color: 'white' }}>
                                    <tr className=' headerContainer'>
                                        <td className='sticky-column' rowSpan="2">
                                            <div style={{ textAlign: 'left' }} className='ps-3'>
                                                Asignatura
                                            </div>

                                        </td>
                                        {
                                            numberDay.map((num, i) =>
                                                <td key={i} rowSpan="2">{num}</td>
                                            )
                                        }

                                        <td colSpan={4}>Este mes</td>
                                        <td colSpan={2}>Anteriores</td>
                                        <td rowSpan="2" className='fs-11'>Total</td>
                                        <td rowSpan="2" className='fs-11'>Total just.</td>
                                        <td rowSpan="2" className='fs-11'>Total injust.</td>
                                    </tr>
                                    <tr className='headerContainer'>
                                        <td className='fs-11'>Asisten.</td>
                                        <td className='fs-11'>Just.</td>
                                        <td className='fs-11'>Injust.</td>
                                        <td className='fs-11'>N/C</td>
                                        <td className='fs-11'>Total</td>
                                        <td className='fs-11'>Just.</td>
                                    </tr>
                                </thead><tbody>
                                    {assistance && assistance.map((std, i) =>
                                        studentRowAssist(std, i)
                                    )}
                                </tbody>
                            </table>

                        </div>
                    }
                </Card>
            </div>

            <Modal show={showAssistanceDetail} onHide={handleCloseModalAssistanceDetail} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faClipboardUser} style={{ minWidht: '20px' }} /><Modal.Title>Detalle de asistencia</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: 'white' }}>
                    {studentAssistanceDetailModal != null && <>
                        <InfoField title='Fecha' extraClass='mb-2' content={`${studentAssistanceDetailModal.date.day}/${selectedDateNumber < 10 ? `0${selectedDateNumber}` : selectedDateNumber} `} />
                        <InfoField title='Asistencia' extraClass='mb-2' content={studentAssistanceDetailModal.AssistanceName} />
                        {studentAssistanceDetailModal.date.hour != null && <InfoField title='Hora' content={moment(formatHour(studentAssistanceDetailModal.date.hour)).format("HH:mm")} extraClass='mb-2' />}
                        {studentAssistanceDetailModal.date.fileId != null && <InfoField title='Certificado' content={<><Button variant="secondary" onClick={() => downloadFile(studentAssistanceDetailModal.date.fileId)} className='fs-14'><FontAwesomeIcon className='mr-10' icon={faFileArrowDown} />Descargar</Button></>} />}
                    </>}
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseModalAssistanceDetail}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AssistanceStudent