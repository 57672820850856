import React, { Fragment, useEffect, useState } from 'react'
import { Col, Dropdown, Row, Card, Modal, ToggleButton, Badge, Button, Spinner, Form } from 'react-bootstrap';
import Navbar from '../../navbar/Navbar';
import { faClipboardUser, faChalkboardTeacher, faPen, faCaretUp, faCaretDown, faCalendar, faCheck, faArrowLeft, faPlus, faTimes, faFile, faPenFancy, faUser, faFileCircleExclamation, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import moment from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { useLocation } from "react-router-dom";
import { GET, POST, PUT } from '../../../controllers/endpoints.controller';
import { getUserRole, getUserToken, logout } from '../../../controllers/user.controller';
import Loading from '../../general-components/Loading';
import { getMonthsActualYear } from '../../../tools/tools';
import NotFound from '../../general-components/NotFound';
import { OverlayTrigger, Tooltip } from 'react-bootstrap/esm';
registerLocale('es', es)


function EvaluativeReport() {
    const PAGE_TITLE = "Calificaciones";
    const PAGE_ICON = faPen;
    const PREVIOUS_PAGE = "";
    const PREVIOUS_URL = "";

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        getClassrooms();
    }, []);

    const location = useLocation();
    const navigate = useNavigate();

    const [isTableLoaded, setIsTableLoaded] = useState(false);

    const [classrooms, setClassrooms] = useState([]);
    const [classes, setClasses] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [nodals, setNodals] = useState([]);
    const [students, setStudents] = useState([]);
    const [evaluativeGrades, setEvaluativeGrades] = useState([]);
    const [reportSubjects, setReportSubjects] = useState([]);

    const [selectedClassroomOption, setSelectedClassroomOption] = useState('Curso');
    const [selectedClassroomId, setSelectedClassroomId] = useState(null);
    const [selectedClassroomImMainTeacher, setSelectedClassroomImMainTeacher] = useState(false);

    const handleSelectClassroom = (eventKey) => {
        setSelectedClassroomOption(`${classrooms[eventKey].degree}º ${classrooms[eventKey].division}`);
        setSelectedClassroomId(classrooms[eventKey].id)
        setSelectedClassroomImMainTeacher(classrooms[eventKey].IAmMainTeacher);
        getClassesByClassroomId(classrooms[eventKey].id)
        setSelectedClassOption('Materia');
        setSelectedClassId(null);
        setSelectedClassIsGradable(false);
        setSelectedPeriodGeneralOption('Período');
        setSelectedPeriodGeneralId(null);
        setSelectedPeriodNumerical(false);
        setIsTableLoaded(false)
        navigate({
            pathname: location.pathname,
            search: `?id=${classrooms[eventKey].id}`,
        });
    };

    const [selectedClassOption, setSelectedClassOption] = useState('Materia');
    const [selectedClassId, setSelectedClassId] = useState(null);
    const [selectedClassIsGradable, setSelectedClassIsGradable] = useState(false);

    const handleSelectClass = (eventKey) => {
        setSelectedClassOption(classes[eventKey].name);
        setSelectedClassId(classes[eventKey].id);
        setSelectedClassIsGradable(classes[eventKey].isGradable);

        if (classes[eventKey].id != null && selectedPeriodGeneralId != null) {
            getEvaluativeReport(selectedPeriodGeneralId, classes[eventKey].id)
        }
    };

    const [selectedPeriodOption, setSelectedPeriodOption] = useState('Período');
    const [selectedPeriodId, setSelectedPeriodId] = useState(null);
    const [selectedPeriodNumerical, setSelectedPeriodNumerical] = useState(false);

    const handleSelectPeriod = (eventKey) => {
        handleSelectPeriodGeneral(eventKey);
    };

    const [selectedPeriodGeneralOption, setSelectedPeriodGeneralOption] = useState('Período');
    const [selectedPeriodGeneralId, setSelectedPeriodGeneralId] = useState(null);

    const handleSelectPeriodGeneral = (eventKey) => {
        setSelectedPeriodGeneralOption(periods[eventKey].name);
        setSelectedPeriodGeneralId(periods[eventKey].id);
        setSelectedPeriodOption(periods[eventKey].name);
        setSelectedPeriodId(periods[eventKey].id);
        setSelectedPeriodNumerical(periods[eventKey].PeriodGradeTypes[0].isNumerical);
        getEvaluativeReport(periods[eventKey].id, selectedClassId)
    };

    const [selectedStudentOption, setSelectedStudentOption] = useState('Alumno');
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [selectedStudentIndex, setSelectedStudentIndex] = useState(0)

    const handleSelectStudent = (eventKey) => {
        if (eventKey >= students.length) {
            eventKey = 0;
        }
        setSelectedStudentOption(`${students[eventKey].surname && students[eventKey].surname.toUpperCase()}, ${students[eventKey].name}`);
        setSelectedStudentId(students[eventKey].id);
        showOldGrades(eventKey);
        setSelectedStudentIndex(eventKey)
    };

    const handleConfirmAndNextStudent = () => {
        if(changesInModal[selectedStudentIndex]){
            postPeriodGrades();
        }
        handleSelectStudent(selectedStudentIndex + 1)
    }

    const [showQualifyPeriodModal, setShowQualifyPeriodModal] = useState(false);

    const handleShowQualifyPeriodModal = () => {
        setSelectedStudentId(students[0].id);
        setSelectedStudentOption(`${students[0].surname && students[0].surname.toUpperCase()}, ${students[0].name}`);
        showOldGrades(0);
        setSelectedStudentIndex(0)
        getEvaluativeGrades();
        setIsPostReady(-1);
        setShowQualifyPeriodModal(true);
        setChangesInModal(Array(students.length).fill(false));
    };

    const handleCloseQualifyPeriodModal = () => {
        if(changesInModal.find(r => r==true)){
            getEvaluativeReport(selectedPeriodGeneralId, selectedClassId);
        }
        setShowQualifyPeriodModal(false);
    };

    const [changesInModal, setChangesInModal] = useState([])
    const [selectedEvaluativeGrades, setSelectedEvaluativeGrades] = useState([]);
    const [selectedNumericalGrades, setSelectedNumericalGrades] = useState([]);

    const handleSelectEvaluativeGrades = (i, grade) => {
        let auxEG = [...selectedEvaluativeGrades];
        auxEG[i] = grade;
        setSelectedEvaluativeGrades(auxEG);
        setChangesInModal(prev => {
            let prevArr = changesInModal
            prevArr[selectedStudentIndex] = true
            return prevArr
        })
    };

    const handleSelectNumericalGrades = (i, grade) => {
        let auxNG = [...selectedNumericalGrades];
        
        let value = grade.target.value.replace(",", ".");
        let numericalValue = parseFloat(value);
        if (numericalValue > 10) {
            value = numericalValue % 100 == 0 ? 10 : value.charAt(0);
            numericalValue = parseFloat(grade.target.value);
        }
        if(numericalValue < 0){
            value = "";
        }
        grade.target.value = value;
        auxNG[i] = value != "" ? (!isNaN(value) ? parseFloat(value) : -1) : -1;
        
        //auxNG[i] = grade.target.value != "" ? (!isNaN(grade.target.value) ? parseFloat(grade.target.value) : -1) : -1;
        setSelectedNumericalGrades(auxNG);
        setChangesInModal(prev => {
            let prevArr = changesInModal
            prevArr[selectedStudentIndex] = true
            return prevArr
        })
    };

    const resetGrades = () => {
        let egs = [];
        let ngs = [];
        for (let i = 0; i <= nodals.length; i++) { //nodals.length en vez de students
            egs[i] = {
                id: 0,
                name: '---',
                value: '---',
                approved: null,
                equivalent: -1
            };
            ngs[i] = -1;
        }
        setSelectedEvaluativeGrades(egs);
        setSelectedNumericalGrades(ngs);
    }

    const getParams = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const getClassrooms = async function () {
        if (getUserRole() == "Docente Primaria") {
            const req = await GET(`/classes/classrooms/byTeacherId`, getUserToken());
            const res = await req.json();
            console.log(res)
            setClassrooms(res);
            if (req.status === 200) {
                let classroomIdUrl = getParams();
                if (classroomIdUrl >= 0) {
                    let index = res.findIndex(item => item.id == classroomIdUrl);
                    index = index >= 0 ? index : 0;
                    getClassesByClassroomId(res[index].id)
                    setSelectedClassroomOption(`${res[index].degree}º ${res[index].division}`);
                    setSelectedClassroomId(res[index].id)
                    setSelectedClassroomImMainTeacher(res[index].IAmMainTeacher);
                } else {
                    getClassesByClassroomId(res[0].id)
                    setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                    setSelectedClassroomId(res[0].id)
                    setSelectedClassroomImMainTeacher(res[0].IAmMainTeacher);
                }
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await GET(`/classes/classrooms/bySchoolYear/${2024}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                let classroomIdUrl = getParams();
                if (classroomIdUrl >= 0) {
                    let index = res.findIndex(item => item.id == classroomIdUrl);
                    index = index >= 0 ? index : 0;
                    getClassesByClassroomId(res[index].id)
                    setSelectedClassroomOption(`${res[index].degree}º ${res[index].division}`);
                    setSelectedClassroomId(res[index].id)
                } else {
                    getClassesByClassroomId(res[0].id)
                    setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                    setSelectedClassroomId(res[0].id)
                }
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }

    }

    const getClassesByClassroomId = async function (cid) {
        if (getUserRole() == "Docente Primaria") {
            const req = await GET(`/classes/classes/byTeacherId&ClassroomId/${cid}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClasses(res);
                getPeriods(cid);
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await GET(`/classes/classes/byClassroomId/${cid}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClasses(res);
                getPeriods(cid);
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const getPeriods = async function (cid) {
        const req = await GET(`/grades/periods/byClassroomId/noGrouped/${cid}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPeriods(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getEvaluativeGrades = async function () {
        const req = await GET(`/grades/evaluativeGrades/showNodals`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            let a = [{
                approved: true,
                equivalent: -1,
                id: null,
                name: "---",
                value: "---"
            }];
            let aa = a.concat(res)
            setEvaluativeGrades(aa);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getEvaluativeReport = async function (pid, cid) {
        setIsTableLoaded(false);
        const req = await GET(`/grades/evaluativeReport/byPeriodId&ClassId/${pid}/${cid}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            if (res.students) { setStudents(res.students) }
            if (res.nodals) { setNodals(res.nodals) }
            if (res.reportSubjects) { setReportSubjects(res.reportSubjects) }
            setIsTableLoaded(true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const passingGrade = 6;

    const isApprovedBadge = (i) => {
        const aBadge = (<Badge bg='' style={{ background: 'green' }} className='statusBadge fs-14'>A</Badge>);
        const dBagde = (<Badge bg='' style={{ background: 'red' }} className='statusBadge fs-14'>D</Badge>);

        if (selectedNumericalGrades[i] != null) {
            if (selectedNumericalGrades[i] >= passingGrade) {
                return aBadge;
            } else if (selectedNumericalGrades[i] == -1) {

            } else {
                return dBagde;
            }
        }

        if (selectedEvaluativeGrades[i] != null) {
            if (selectedEvaluativeGrades[i].value != '---') {
                if (selectedEvaluativeGrades[i].approved == true) {
                    return aBadge;
                } else {
                    return dBagde;
                }
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    const [isPostReady, setIsPostReady] = useState(-1);
    const postPeriodGrades = async function (lastConfirmation = false) {
        let g = [];
        setIsPostReady(false)
        for (let i = 0; i < nodals.length; i++) {
            if (selectedEvaluativeGrades[i]) {
                if (selectedEvaluativeGrades[i].value != '---') {
                    g.push({
                        nodalId: nodals[i].id,
                        evaluativeId: selectedEvaluativeGrades[i].id
                    })
                }
            }

            if (selectedNumericalGrades[i]) {
                if (selectedNumericalGrades[i] != -1) {
                    g.push({
                        nodalId: nodals[i].id,
                        numerical: selectedNumericalGrades[i]
                    })
                }
            }
        }

        let rs;

        if (selectedPeriodNumerical == true) {
            rs = { numerical: selectedNumericalGrades[nodals.length] != -1 ? selectedNumericalGrades[nodals.length] : null }
        } else {
            rs = { evaluativeId: selectedEvaluativeGrades[nodals.length].id != 0 ? selectedEvaluativeGrades[nodals.length].id : null }
        }

        const body = {
            nodalAverages: g,
            reportSubject: rs,
            studentId: selectedStudentId,
            periodId: selectedPeriodId,
            classId: selectedClassId,
        }

        const req = await PUT(`/grades/qualifyNodal&Period/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            if (lastConfirmation) {
                getEvaluativeReport(selectedPeriodGeneralId, selectedClassId);
                handleCloseQualifyPeriodModal();
            } else {
                let element = document.getElementById("generalMarkNumerical");
                if (element) {
                    element.value = "";
                }
            }
            console.log(res);
            /* resetGrades();
            setSelectedStudentId(null);
            setSelectedStudentOption("Alumno"); */
            setIsPostReady(-1);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const GradeValue = ({ g }) => {
        if (g.numerical) {
            //Numerical grade
            if (g.numerical == "ND") {
                return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 60, backgroundColor: 'white' }}>--</Badge>)
            } else if (g.numerical >= passingGrade) {
                //Approved
                return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 60, backgroundColor: 'green' }}>{g.numerical}</Badge>)
            } else {
                return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 60, backgroundColor: 'red' }}>{g.numerical}</Badge>)
            }
        } else if (g.EvaluativeGrade) {
            //Evaluative grade
            if (g.EvaluativeGrade.approved) {
                return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 60, backgroundColor: 'green' }}>{g.EvaluativeGrade.value}</Badge>)
            } else {
                return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 60, backgroundColor: 'red' }}>{g.EvaluativeGrade.value}</Badge>)
            }
        } else {
            return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 60, backgroundColor: 'white' }}>-</Badge>);
        }
    }

    const [hoverContent, setHoverContent] = useState({}); //[testId] = true/false, si esta con hover o no.

    const handleMouseEnter = (id) => {
        setHoverContent((prevState) => ({
            ...prevState,
            [id]: true,
        }))
    };
    const handleMouseLeave = (id) => {
        setHoverContent((prevState) => ({
            ...prevState,
            [id]: false,
        }));
    };

    const functEdit = (userId, index) => {
        /*         handleShowQualifyPeriodModal(); */
        setSelectedStudentOption(`${students[index].surname && students[index].surname.toUpperCase()}, ${students[index].name}`);
        setSelectedStudentId(userId);
        setSelectedStudentIndex(index)
        showOldGrades(index);
        getEvaluativeGrades();
        setIsPostReady(-1);
        setShowQualifyPeriodModal(true);
    }

    const showOldGrades = (studentIndex) => {
        let egs = [];
        let ngs = [];

        for (let i = 0; i < nodals.length; i++) {
            if (nodals[i].averages[studentIndex].EvaluativeGrade != null) {
                egs[i] = {
                    id: nodals[i].averages[studentIndex].EvaluativeGrade.id,
                    name: nodals[i].averages[studentIndex].EvaluativeGrade.name,
                    value: nodals[i].averages[studentIndex].EvaluativeGrade.value,
                    approved: nodals[i].averages[studentIndex].EvaluativeGrade.approved,
                    equivalent: nodals[i].averages[studentIndex].EvaluativeGrade.equivalent,
                };
            } else {
                egs[i] = {
                    id: 0,
                    name: '---',
                    value: '---',
                    approved: null,
                    equivalent: -1
                };
            }
            if (nodals[i].averages[studentIndex].numerical != null) {
                ngs[i] = nodals[i].averages[studentIndex].numerical;
            } else {
                ngs[i] = -1;
            }

        }
        if (reportSubjects[studentIndex] && reportSubjects[studentIndex].numerical != null) {
            ngs[nodals.length] = reportSubjects[studentIndex].numerical;
        } else {
            ngs[nodals.length] = -1;
        }

        if (reportSubjects[studentIndex] && reportSubjects[studentIndex].EvaluativeGrade != null) {
            egs[nodals.length] = {
                id: reportSubjects[studentIndex].EvaluativeGrade.id,
                name: reportSubjects[studentIndex].EvaluativeGrade.name,
                value: reportSubjects[studentIndex].EvaluativeGrade.value,
                approved: reportSubjects[studentIndex].EvaluativeGrade.approved,
                equivalent: reportSubjects[studentIndex].EvaluativeGrade.equivalent,
            }
        } else {
            egs[nodals.length] = {
                id: 0,
                name: '---',
                value: '---',
                approved: null,
                equivalent: -1
            };
        }

        setSelectedEvaluativeGrades(egs);
        setSelectedNumericalGrades(ngs);
    }

    useEffect(() => {
        if (showQualifyPeriodModal) {
            resetGrades();
        }
    }, [nodals])

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}
                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <div className='d-block d-lg-none mt-3'></div>
                <div className='d-none d-lg-block mt-4'></div>
                <Row className=''>
                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-100">
                                    {selectedClassroomOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassroomOption}>
                                    {classrooms.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectClassroom(i)}>{opt.degree}º {opt.division}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faPen} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-250" disabled={selectedClassroomId == null}>
                                    {selectedClassOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassOption}>
                                    {classes.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectClass(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>


                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-175" disabled={selectedClassId == null}>

                                    {selectedPeriodGeneralOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedPeriodGeneralOption}>
                                    {periods.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectPeriodGeneral(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>

                    <Col className='endRow'>
                        {(selectedClassroomImMainTeacher == true || getUserRole() == "Dirección Primaria" || getUserRole() == "Director Primaria") &&
                            <ActionButton url={`/reportes/observaciones-primaria/?curso=${selectedClassroomId}`} iconFa={faUser} title={'Observaciones'} func={handleShowQualifyPeriodModal} classExtra='fs-14' />
                        }
                        <ActionButton disabled={selectedPeriodGeneralId == null} iconFa={faPen} title={'Calificar período'} func={handleShowQualifyPeriodModal} classExtra='fs-14 ms-2' />
                    </Col>
                </Row>

                <Card className='cards mt-3 h-75'>
                    {!isTableLoaded ?
                        <div className='justify-content-center'>
                            {selectedPeriodGeneralId != null && selectedClassId != null ?
                                <Loading animation={'border'} classExtra='loading-100' />
                                :
                                <NotFound classExtra="mt-5" iconFa={faFileCircleExclamation} text='Seleccioná un curso, una materia y un período' />
                            }
                        </div> :
                        <div className='slideTable fs-14 ' style={{ borderRadius: '10px' }}>
                            <table className='br-15 table m-0' style={{ borderColor: 'white' }}>
                                <thead className='sticky-row headerBlue' style={{ color: 'white' }}>
                                    <tr className=' headerContainer' style={{ height: 60 }}>
                                        <td className='sticky-column'>Eje</td>
                                        {students.map((s, i) => (<>
                                            {/* <td><div style={{ width: 100 }} className='fs-11'>{s.surname && s.surname.toUpperCase()}, {s.name}</div></td> */}
                                            <OverlayTrigger placement="top" delay={{ show: 20, hide: 20 }} overlay={<Tooltip id="button-tooltip" className="custom-tooltip" >
                                                Calificar
                                            </Tooltip>}>
                                                <td className='p-0' onMouseEnter={() => handleMouseEnter(s.id)} onMouseLeave={() => handleMouseLeave(s.id)}>
                                                    {hoverContent[s.id] ?
                                                        <div style={{ width: 100, height: 59, cursor: 'pointer' }} onClick={() => functEdit(s.id, i)} className='editGrades fs-11' ><FontAwesomeIcon icon={faPen} /></div>
                                                        :
                                                        <div style={{ width: 100 }} className='fs-11'>{s.surname && s.surname.toUpperCase()}, {s.name}</div>
                                                    }</td>
                                            </OverlayTrigger></>
                                        ))}
                                    </tr>

                                </thead>
                                <tbody>
                                    {nodals.map((n, i) => (
                                        <tr>
                                            <td className='sticky-column pb-0 pt-1 ps-0 pe-0'>
                                                <Row className='tableRowsBackground pt-2 pb-2' style={{ width: 300, height: 95 }}>
                                                    <Col className='align-center-vertically-v2 ps-3 pe-1'>
                                                        <div style={{ textAlign: 'left' }}>
                                                            {n.name}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </td>

                                            {n.averages.map((na, i) => (
                                                <td className='pb-0 pt-1 ps-0 pe-0' style={{ width: 100, height: 95 }}>
                                                    <Row className='tableRows' style={{ height: '100%' }}>
                                                        <Col className='align-center-vertically-v2 '>
                                                            <div className='m-0 p-0' style={{ textAlign: 'center' }}>
                                                                <GradeValue g={na} />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                </td>
                                            ))}
                                        </tr>
                                    ))}

                                    {selectedClassIsGradable == true &&

                                        <tr>
                                            <td className='sticky-column pb-0 pt-1 ps-0 pe-0'>
                                                <Row className='tableRowsBackground pt-2 pb-2' style={{ width: 300, height: 50 }}>
                                                    <Col className='align-center-vertically-v2 ps-3 pe-1'>
                                                        <div style={{ textAlign: 'left' }}>
                                                            Calificación general
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </td>

                                            {reportSubjects.map((rs, i) => (
                                                <td className='pb-0 pt-1 ps-0 pe-0' style={{ width: 100, height: 50 }}>
                                                    <Row className='tableRows' style={{ height: '100%' }}>
                                                        <Col className='align-center-vertically-v2 '>
                                                            <div className='m-0 p-0' style={{ textAlign: 'center' }}>
                                                                <GradeValue g={rs} />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                </td>
                                            ))}
                                        </tr>}

                                </tbody>
                            </table>

                        </div>
                    }
                </Card>
            </div>

            {showQualifyPeriodModal && <Modal size={'lg'} show={showQualifyPeriodModal} onHide={handleCloseQualifyPeriodModal} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faClipboardUser} style={{ minWidht: '20px' }} /><Modal.Title>Calificar período</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                        {selectedPeriodOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedPeriodOption}>
                                        {periods.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectPeriod(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>

                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faUser} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                        {selectedStudentOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedStudentOption}>
                                        {students.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectStudent(i)}>{opt.surname && opt.surname.toUpperCase()}, {opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>

                    <Row className='p-2 mt-3' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                        <Col md={8} className='align-center-vertically-v2'>
                            <div style={{ textAlign: 'left' }}>
                                Eje
                            </div>
                        </Col>
                        <Col md={2} className='align-center-vertically-v2'>
                        </Col>
                        <Col md={2} className='align-center-vertically-v2'>
                            <div style={{ textAlign: 'center' }}>
                                Nota
                            </div>
                        </Col>
                    </Row>
                    {isTableLoaded && isPostReady==-1 ?
                        <Row className='mt-2'>
                            {nodals.map((n, i) => (
                                <Row key={i} className='mb-1 pt-1 pb-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={8} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'left' }}>
                                            {n.name}
                                        </div>
                                    </Col>


                                    <Col md={2} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'right' }}>
                                            {isApprovedBadge(i)}
                                        </div>
                                    </Col>

                                    <Col md={2} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {selectedEvaluativeGrades.length > 0 &&
                                                <Dropdown className='dropdownButton'>
                                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 w-100">
                                                        {selectedEvaluativeGrades[i] && selectedEvaluativeGrades[i].value}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="fs-14" title={selectedEvaluativeGrades[i] && selectedEvaluativeGrades[i].value}>
                                                        {evaluativeGrades.map((opt, j) => (
                                                            <Dropdown.Item key={j} onClick={() => handleSelectEvaluativeGrades(i, opt)}>{opt.name}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            ))}

                            {selectedClassIsGradable == true &&
                                <Row className='mt-4 mb-3 pt-1 pb-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={8} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'left' }}>
                                            Calificación general
                                        </div>
                                    </Col>


                                    <Col md={2} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'right' }}>
                                            {isApprovedBadge(nodals.length)}
                                        </div>
                                    </Col>

                                    <Col md={2} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {selectedPeriodNumerical != null &&
                                                <span>
                                                    {selectedPeriodNumerical == true ?
                                                        <Form.Control className='textInputGray fs-14 w-100' id="generalMarkNumerical" placeholder={selectedNumericalGrades[nodals.length] != null && selectedNumericalGrades[nodals.length] != -1 && selectedNumericalGrades[nodals.length]} onChange={(event) => handleSelectNumericalGrades(nodals.length, event)} />
                                                        :
                                                        <>
                                                            {selectedEvaluativeGrades.length > 0 &&
                                                                <Dropdown className='dropdownButton'>
                                                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 w-100">
                                                                        {selectedEvaluativeGrades[nodals.length] && selectedEvaluativeGrades[nodals.length].value}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="fs-14" title={selectedEvaluativeGrades[nodals.length] && selectedEvaluativeGrades[nodals.length].value}>
                                                                        {evaluativeGrades.map((opt, j) => (
                                                                            <Dropdown.Item key={j} onClick={() => handleSelectEvaluativeGrades(nodals.length, opt)}>{opt.name}</Dropdown.Item>
                                                                        ))}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            }
                                                        </>
                                                    }
                                                </span>
                                            }
                                        </div>
                                    </Col>
                                </Row>}
                        </Row> : <div className='justify-content-center'>
                            <Loading animation={'border'} classExtra='loading-100' />
                        </div>}

                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseQualifyPeriodModal} disabled={isPostReady != -1}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={() => postPeriodGrades(true)} disabled={selectedStudentId == null || isPostReady != -1 || nodals.length == 0}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar </Button>
                    <Button variant="primary" className='fs-14' onClick={handleConfirmAndNextStudent} disabled={selectedStudentId == null || isPostReady != -1 || nodals.length == 0}><FontAwesomeIcon className='mr-10' icon={faArrowRight} />Confirmar e ir al siguiente</Button>
                </Modal.Footer>
            </Modal>}


        </div>
    )
}

export default EvaluativeReport