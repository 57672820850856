import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../../navbar/Navbar';
import { Accordion, Badge, Button, Card, Col, Dropdown, DropdownMenu, Form, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faCalendar, faChalkboardTeacher, faCheck, faChevronRight, faClipboardCheck, faClipboardUser, faEllipsisV, faExclamationCircle, faEye, faFile, faFileCircleExclamation, faList, faPen, faPenAlt, faPlus, faPlusCircle, faPlusMinus, faSearch, faTimes, faToggleOn, faTrash, faUser, faUserCircle, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { getFamilyMemberId, getFamilyMemberToken, getUserId, getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { DEL, GET, POST, PUT } from '../../../controllers/endpoints.controller';
import moment from 'moment';
import { formatNameV2, getTextColorForBackground, pluralize } from '../../../tools/tools';
import ReactDatePicker from 'react-datepicker';
import InfoField from '../../general-components/InfoField';
import Loading from '../../general-components/Loading';
import NotFound from '../../general-components/NotFound';

function SanctionsByStudents() {
    const PAGE_TITLE = "Sanciones";
    const PAGE_ICON = faExclamationCircle;

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
        getSanctionsByUserId(getFamilyMemberId())

    }, []);

    const [myRole, setMyRole] = useState(getUserRole());
    const [userToken, setUserToken] =  useState(myRole.includes("Familia") || myRole.includes("Padre-Madre-Tutor") ? getFamilyMemberToken() : getUserToken());

    const getSanctionsByUserId = async function (userId) {
        setIsTableLoaded(false)
        const req = await GET(`/sanctions/byUserId/${userId}`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            setIsTableLoaded(true)
            console.log(res)
            setSanctions(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [sanctions, setSanctions] = useState(null);

    const [status, setStatus] = useState([{ name: 'Todas', value: "null" }, { name: 'Aprobadas', value: "approved" }, { name: 'Desaprobadas', value: "disapproved" }]);
    const [selectedStatusOption, setSelectedStatusOption] = useState({ name: 'Todas', value: "null" });
    const handleSelectStatus = (opt) => {
        setSelectedStatusOption(opt);
    };


    const [isTableLoaded, setIsTableLoaded] = useState(false);

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <div className='d-block d-lg-none' style={{ height: '70px' }}> </div>

                <Row className='toolbar m-0'>
                    <Col>
                        <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <div className='d-block d-lg-none mt-3'></div>
                <div className='d-none d-lg-block mt-4'></div>
                {/*<Row className='mb-3 align-items-center ' style={{ rowGap: '8px' }}>
                     <Col className='fitContentWidth'>
                        <div className='align-center-vertically '>
                            <FontAwesomeIcon icon={faFile} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-150">
                                    {selectedStatusOption && selectedStatusOption.name}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedStatusOption && selectedStatusOption.name}>
                                    {status && status.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectStatus(opt)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col> 


                </Row>*/}
                <Row>
                    <Card className='cards mobile-cards'>
                        {isTableLoaded &&
                            (sanctions && sanctions.sanctions && sanctions.sanctions.length > 0 ? <>
                                <div className='d-none d-lg-block'>
                                    <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>

                                        <Col md={1} className='align-center-vertically-v2 pe-0'>
                                            <div style={{ textAlign: 'left' }}>
                                                Fecha
                                            </div>
                                        </Col>
                                        <Col md={5} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'left' }}>
                                                Sanción
                                            </div>
                                        </Col>
                                        <Col md={3} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                Solicitante
                                            </div>
                                        </Col>
                                        <Col md={2} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                Estado
                                            </div>
                                        </Col>
                                        <Col md={1} className='align-center-vertically-v2'>

                                        </Col>
                                    </Row>
                                </div>
                                <div className='d-block d-lg-none'>
                                    <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                                        <Col md={12} className='align-center-vertically-v2 pe-0'>
                                            <div style={{ textAlign: 'center' }}>
                                                Sanciones
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {sanctions != null && sanctions.sanctions && sanctions.sanctions.map((r, i) => myRole.includes('Familia') || myRole.includes('Padre-Madre-Tutor') || myRole.includes('Alumno') ? r.approved && (<>
                                    <div key={i}>
                                        <div className='d-none d-lg-block'>
                                            <Row className='mb-2 ps-2 pe-2 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Col md={1} className='dateCol align-center-vertically-v2 pe-0'>
                                                    <div style={{ textAlign: 'left' }} className=' '>
                                                        {moment(r.date).format("DD/MM/YY")}
                                                    </div>
                                                </Col>
                                                <Col md={5} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'left' }}>
                                                        {r.SanctionType && <Badge bg='' className='me-2' style={{ fontWeight: 400, color: r.SanctionType.color ? getTextColorForBackground(r.sanctionType.color) : 'black', backgroundColor: r.SanctionType.color ? r.sanctionType.color : '#ffc107' }} >{`${r.amount} ${r.amount > 1 ? pluralize(r.SanctionType.name) : r.SanctionType.name}`}</Badge>}

                                                        {r.SanctionReason != null ? r.SanctionReason.name && r.SanctionReason.name : ''}

                                                        {(r.content != null && r.content != "") ? `${r.SanctionReason != null && ":"} ${r.content}` : ''}
                                                    </div>
                                                </Col>
                                                <Col md={3} className='align-center-vertically-v2'>

                                                    <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                                                        <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faChalkboardTeacher} className='me-1' style={{ fontSize: '11px' }} />{r.from && r.from.surname ? r.from.surname.toUpperCase() : ''}, {r.from && r.from.name}</Badge>
                                                    </div>

                                                </Col>

                                                <Col md={2} className='align-center-vertically-v2' >
                                                    <div>
                                                        <p className='m-0 ps-2' style={{ display: 'flex', justifyContent: 'center' }}><Badge bg={r.approved ? 'success' : 'warning'} style={{ color: `${r.approved ? 'white' : 'black'}` }} className='statusBadge fs-14 wi-100 '>{r.approved ? 'Aprobada' : 'Pendiente'}</Badge></p>
                                                    </div>
                                                </Col>

                                                <Col md={1} className='align-center-vertically-v2'>

                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='d-block d-lg-none'>
                                            <div className='mb-2 ps-3 pe-3 pt-2 pb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Row className='ps-0'>

                                                    <div style={{ textAlign: 'left' }} className='fitContentWidth ps-0'>
                                                        <span className='fs-11'>{moment(r.date).format("DD/MM/YYYY")}</span>
                                                    </div>
                                                    <div className='fitContentWidth ps-0 align-center-vertically'>
                                                        {r.SanctionType && <Badge bg='warning' style={{ fontWeight: 400, color: 'black' }} >{`${r.amount} ${r.amount > 1 ? pluralize(r.SanctionType.name) : r.SanctionType.name}`}</Badge>
                                                        }</div>
                                                    <div className='fitContentWidth ps-0 align-center-vertically'>
                                                        <Badge style={{ fontWeight: 400, color: `${r.approved ? 'white' : 'black'}` }} bg={r.approved ? 'success' : 'warning'} className=' pointer'>{r.approved ? 'Aprobada' : 'Pendiente'}</Badge>
                                                    </div>

                                                </Row>
                                                <Row className='pt-2 pb-2 ps-0'>
                                                    <Col xs={10} className='align-center-vertically-v2 ps-0'>
                                                        <div style={{ textAlign: 'left' }}>
                                                            {r.SanctionReason != null ? r.SanctionReason.name && r.SanctionReason.name : ''}

                                                            {(r.content != null && r.content != "") ? `${r.SanctionReason != null && ":"} ${r.content}` : ''}
                                                        </div>
                                                    </Col>
                                                    <Col xs={2} className='align-center-vertically-v2 pe-0'>

                                                    </Col>
                                                </Row>
                                                <Row className='ps-0'>
                                                    <Col md={12} className='align-center-vertically-v2 ps-0'>
                                                        <div id={`student-${r.id}`} style={{ textAlign: 'center', display: 'flex', columnGap: '5px', rowGap: '5px', flexWrap: 'wrap' }} className='mb-1'>
                                                            {r.to && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faUser} className='me-1' style={{ fontSize: '11px' }} />{formatNameV2(r.to.name, r.to.surname)}</Badge>}
                                                            {r.to && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} className='ms-1'>{r.to.degree && r.to.division && `${r.to.degree}°${r.to.division}`}</Badge>}
                                                            {r.from && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faChalkboardTeacher} className='me-1' style={{ fontSize: '11px' }} />{formatNameV2(r.from.name, r.from.surname)}</Badge>
                                                            }</div>
                                                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>


                                                        </div>

                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>

                                </>) :
                                    (<>
                                        <div key={i}>
                                            <div className='d-none d-lg-block'>
                                                <Row className='mb-2 ps-2 pe-2 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                    <Col md={1} className='dateCol align-center-vertically-v2 pe-0'>
                                                        <div style={{ textAlign: 'left' }} className=' '>
                                                            {moment(r.date).format("DD/MM/YY")}
                                                        </div>
                                                    </Col>
                                                    <Col md={5} className='align-center-vertically-v2'>
                                                        <div style={{ textAlign: 'left' }}>
                                                            {r.SanctionType && <Badge bg='' className='me-2' style={{ fontWeight: 400, color: r.SanctionType.color ? getTextColorForBackground(r.sanctionType.color) : 'black', backgroundColor: r.SanctionType.color ? r.sanctionType.color : '#ffc107' }} >{`${r.amount} ${r.amount > 1 ? pluralize(r.SanctionType.name) : r.SanctionType.name}`}</Badge>}

                                                            {r.SanctionReason != null ? r.SanctionReason.name && r.SanctionReason.name : ''}

                                                            {(r.content != null && r.content != "") ? `${r.SanctionReason != null && ":"} ${r.content}` : ''}
                                                        </div>
                                                    </Col>
                                                    <Col md={3} className='align-center-vertically-v2'>

                                                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                                                            <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faChalkboardTeacher} className='me-1' style={{ fontSize: '11px' }} />{r.from && r.from.surname ? r.from.surname.toUpperCase() : ''}, {r.from && r.from.name}</Badge>
                                                        </div>

                                                    </Col>

                                                    <Col md={2} className='align-center-vertically-v2' >
                                                        <div>
                                                            <p className='m-0 ps-2' style={{ display: 'flex', justifyContent: 'center' }}><Badge bg={r.approved ? 'success' : 'warning'} style={{ color: `${r.approved ? 'white' : 'black'}` }} className='statusBadge fs-14 wi-100 '>{r.approved ? 'Aprobada' : 'Pendiente'}</Badge></p>
                                                        </div>
                                                    </Col>

                                                    <Col md={1} className='align-center-vertically-v2'>

                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='d-block d-lg-none'>
                                                <div className='mb-2 ps-3 pe-3 pt-2 pb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                    <Row className='ps-0'>

                                                        <div style={{ textAlign: 'left' }} className='fitContentWidth ps-0'>
                                                            <span className='fs-11'>{moment(r.date).format("DD/MM/YYYY")}</span>
                                                        </div>
                                                        <div className='fitContentWidth ps-0 align-center-vertically'>
                                                            {r.SanctionType && <Badge bg='warning' style={{ fontWeight: 400, color: 'black' }} >{`${r.amount} ${r.amount > 1 ? pluralize(r.SanctionType.name) : r.SanctionType.name}`}</Badge>
                                                            }</div>
                                                        <div className='fitContentWidth ps-0 align-center-vertically'>
                                                            <Badge style={{ fontWeight: 400, color: `${r.approved ? 'white' : 'black'}` }} bg={r.approved ? 'success' : 'warning'} className=' pointer'>{r.approved ? 'Aprobada' : 'Pendiente'}</Badge>
                                                        </div>

                                                    </Row>
                                                    <Row className='pt-2 pb-2 ps-0'>
                                                        <Col xs={10} className='align-center-vertically-v2 ps-0'>
                                                            <div style={{ textAlign: 'left' }}>
                                                                {r.SanctionReason != null ? r.SanctionReason.name && r.SanctionReason.name : ''}

                                                                {(r.content != null && r.content != "") ? `${r.SanctionReason != null && ":"} ${r.content}` : ''}
                                                            </div>
                                                        </Col>
                                                        <Col xs={2} className='align-center-vertically-v2 pe-0'>

                                                        </Col>
                                                    </Row>
                                                    <Row className='ps-0'>
                                                        <Col md={12} className='align-center-vertically-v2 ps-0'>
                                                            <div id={`student-${r.id}`} style={{ textAlign: 'center', display: 'flex', columnGap: '5px', rowGap: '5px', flexWrap: 'wrap' }} className='mb-1'>
                                                                {r.to && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faUser} className='me-1' style={{ fontSize: '11px' }} />{formatNameV2(r.to.name, r.to.surname)}</Badge>}
                                                                {r.to && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} className='ms-1'>{r.to.degree && r.to.division && `${r.to.degree}°${r.to.division}`}</Badge>}
                                                                {r.from && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faChalkboardTeacher} className='me-1' style={{ fontSize: '11px' }} />{formatNameV2(r.from.name, r.from.surname)}</Badge>
                                                                }</div>
                                                            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>


                                                            </div>

                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>

                                    </>)
                                )}
                            </> : <div className='justify-content-center'>
                                <NotFound classExtra="loading-100" iconFa={faFileCircleExclamation} text='No se han encontrado sanciones' />
                            </div>)

                        }
                    </Card>
                </Row>
            </div>
        </div>

    )
}

export default SanctionsByStudents