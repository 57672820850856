import React, { useEffect, useState } from 'react'
import './bootstrap/css/bootstrap.min.css';

import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';
import { Row, Col } from "react-bootstrap";
import './App.css';

import Login from "./components/ui/login/Login";
import GoogleAuth from "./components/ui/login/GoogleAuth";
import Index from "./components/ui/Index";
import Home from "./components/ui/home/Home";
import CalendarSite from './components/ui/calendar/CalendarSite';

import { getUserRole } from "./controllers/user.controller";
import Schedules from './components/ui/schedules/Schedules';
import Assistance from './components/ui/assistance/Assistance';
import AssistanceClass from './components/ui/assistance/AssistanceClass';
import AssistanceStudent from './components/ui/assistance/AssistanceStudent';
import Grades from './components/ui/grades/Grades';
import Report from './components/ui/grades/Report';
import EvaluativeReport from './components/ui/grades/EvaluativeReport';
import ReportCardPrimaryLevel from './components/ui/reports/ReportCardPrimaryLevel';
import EvaluativeReportSecondaryLevel from './components/ui/reports/EvaluativeReportSecondaryLevel';
import EvaluativeReportPrimaryLevel from './components/ui/reports/EvaluativeReportPrimaryLevel';
import ObservationsPrimaryLevel from './components/ui/reports/CommentsPrimaryLevel';
import Settings from './components/ui/settings/Settings';
import SchoolYear from './components/ui/settings/SchoolYear';
import Frames from './components/ui/settings/Periods';
import Sanctions from './components/ui/sanctions/Sanctions';
import CoursesSite from './components/ui/courses/Courses';
import StudentSite from './components/ui/courses/StudentSite';
import CoursesConfig from './components/ui/settings/Courses';
import TestConfig from './components/ui/settings/Tests';
import CurriculumConfig from './components/ui/settings/Curriculum';
import StudentsConfig from './components/ui/settings/Students';
import HolidaysConfig from './components/ui/settings/Holidays';
import Classes from './components/ui/settings/Classes';
import Class from './components/ui/settings/Class';
import CurriculumYears from './components/ui/settings/CurriculumYears';
import CurriculumSubjects from './components/ui/settings/CurriculumSubjects';
import GradesConfig from './components/ui/settings/Grades';
import Terms from './components/ui/terms-conditions/Terms';
import Privacy from './components/ui/terms-conditions/Privacy';
import DefaultNodals from './components/ui/settings/DefaultNodals';
import Users from './components/ui/users/Users';
import UserDetail from './components/ui/users/UserDetail';
import Students from './components/ui/students/Students';
import Teachers from './components/ui/teachers/Teachers';
import ScheduleConfig from './components/ui/settings/Schedule';

import DataEntryStudentFile from './components/ui/courses/DataEntryStudentFile';
import Registration from './components/ui/registration/Registration';
import RegistrationDetail from './components/ui/registration/RegistrationDetail';

import NotEnabled from './components/ui/error/NotEnabled';
import ChooseRole from './components/ui/users/ChooseRole';
import Signatures from './components/ui/settings/Signatures';
import Reports from './components/ui/reports/Reports';
import Communications from './components/ui/communications/Communications';
import Indicators from './components/ui/indicators/Indicators';
import ExamTables from './components/ui/examTables/ExamTables';
import PendingSubjects from './components/ui/examTables/PendingSubjects';

import Chat from './components/ui/communications/Chat';
import CommGroups from './components/ui/settings/CommGroups';
import ChatGroups from './components/ui/settings/ChatGroups';
import DataTypesStudents from './components/ui/settings/DataTypesStudents';
import Help from './components/ui/help/Help';
import SanctionsSettings from './components/ui/settings/Sanctions';
import SanctionsByStudents from './components/ui/sanctions/SanctionsByStudent';
import GradesByStudent from './components/ui/grades/GradesByStudents';
import ChooseChild from './components/ui/users/ChooseChild';
import EvaluativeReportSecondaryLevelByStudents from './components/ui/reports/EvaluativeReportSecondaryLevelByStudents';
import CABA_SVP_SECU_Regulation from './components/ui/regulations/CABA-SVP-SECU-Regulation'
import Maintenance from './components/ui/error/Maintenance';
import { getMaintenance } from './tools/constants';
import Loading from './components/general-components/Loading';
import TeachersAssistance from './components/ui/teachers/TeachersAssistance';
import TeacherSite from './components/ui/teachers/TeacherSite';
import PsychopedagogicTags from './components/ui/settings/PsychopedagogicTags';
import InscriptionNextYear from './components/ui/login/InscriptionNextYear';

import Debug from './components/ui/login/Debug';
/* import Testing from './components/Testing'; */


ReactGA.initialize('G-5PBED81TPP');

ReactGA.send({
	hitType: "pageview",
	page: window.location.pathname
});

export function App() {
	const [isMaintenance, setIsMaintenance] = useState(null);

	useEffect(() => {
		getMaintenance().then((maintenance) => {
			setIsMaintenance(maintenance);
		});
	}, []);

	const ProcessRoutes = () => {
		//let r = getUserRole();
		return (
			<Routes>
				{isMaintenance ?
					<>
						<Route path="/mantenimiento" element={<Maintenance />} />
						<Route path="*" element={<Navigate to="/mantenimiento" replace />} />
					</>
					:
					<>

						{/* <Route path="/test-valen" element={<Testing />} />
					 */}
						<Route path="/login" element={<Login />} />
						<Route path="/login/googleauth" element={<GoogleAuth />} />
						<Route path="/" element={<Index />} />
						<Route path="/inicio" element={<Home />} />
						<Route path="/calendario" element={<CalendarSite />} />
						<Route path="/horarios" element={<Schedules />} />
						<Route path="/asistencia-curso" element={<Assistance />} />
						<Route path="/asistencia-materia" element={<AssistanceClass />} />
						<Route path="/estudiante/asistencia" element={<AssistanceStudent />} />
						<Route path="/calificaciones" element={<Grades />} />
						<Route path="/informe-valorativo" element={<EvaluativeReport />} />
						<Route path="/reportes/boletin/vista-previa" element={<ReportCardPrimaryLevel />} />
						<Route path="/reportes/secundaria/informe-valorativo" element={<EvaluativeReportSecondaryLevel />} />
						<Route path="/reportes/calificaciones-primaria" element={<EvaluativeReportPrimaryLevel />} />
						<Route path="/reportes/observaciones-primaria" element={<ObservationsPrimaryLevel />} />
						<Route path="/configuracion" element={<Settings />} />
						<Route path="/configuracion/ciclos-lectivos" element={<SchoolYear />} />
						<Route path="/configuracion/periodos" element={<Frames />} />
						<Route path="/sanciones" element={<Sanctions />} />
						<Route path="/cursos" element={<CoursesSite />} />
						<Route path="/estudiante" element={<StudentSite />} />
						<Route path="/configuracion/cursos" element={<CoursesConfig />} />
						<Route path="/configuracion/evaluaciones" element={<TestConfig />} />
						<Route path="/configuracion/planes-estudio" element={<CurriculumConfig />} />
						<Route path="/configuracion/cursos/alumnos" element={<StudentsConfig />} />
						<Route path="/configuracion/cursos/materias" element={<Classes />} />
						<Route path="/configuracion/cursos/materia" element={<Class />} />
						<Route path="/configuracion/planes-estudio/años" element={<CurriculumYears />} />
						<Route path="/configuracion/planes-estudio/años/materias" element={<CurriculumSubjects />} />
						<Route path="/configuracion/calificaciones" element={<GradesConfig />} />
						<Route path="/configuracion/horarios" element={<ScheduleConfig />} />
						<Route path="/configuracion/feriados" element={<HolidaysConfig />} />
						<Route path="/terminos" element={<Terms />} />
						<Route path="/privacidad" element={<Privacy />} />
						<Route path="/configuracion/nodales-predeterminados" element={<DefaultNodals />} />
						<Route path="/usuarios" element={<Users />} />
						<Route path="/usuarios/detalle" element={<UserDetail />} />
						<Route path="/alumnos" element={<Students />} />
						<Route path="/dataentry" element={<DataEntryStudentFile />} />
						<Route path="/inscripciones" element={<Registration />} />
						<Route path="/inscripciones/detalle" element={<RegistrationDetail />} />
						<Route path="/profesores" element={<Teachers />} />
						<Route path="/no-habilitado" element={<NotEnabled />} />
						<Route path="/seleccionar-rol" element={<ChooseRole />} />
						<Route path="/configuracion/firmas-docentes" element={<Signatures />} />
						<Route path="/reportes/boletin" element={<Reports />} />
						<Route path="/comunicaciones" element={<Communications />} />
						<Route path="/indicadores" element={<Indicators />} />
						<Route path="/mesas-examen" element={<ExamTables />} />
						<Route path="/materias-adeudadas" element={<PendingSubjects />} />
						<Route path="/configuracion/comunicados" element={<CommGroups />} />
						<Route path="/configuracion/gruposChat" element={<ChatGroups />} />
						<Route path="/configuracion/tiposDatos" element={<DataTypesStudents />} />
						<Route path="/configuracion/sanciones" element={<SanctionsSettings />} />
						<Route path="/ayuda" element={<Help />} />
						<Route path="/estudiante/resumen/" element={<StudentSite />} />
						<Route path="/estudiante/sanciones" element={<SanctionsByStudents />} />
						<Route path="/estudiante/calificaciones" element={<GradesByStudent />} />
						<Route path="/seleccionar-estudiante" element={<ChooseChild />} />
						<Route path="/estudiante/informe-valorativo" element={<EvaluativeReportSecondaryLevelByStudents />} />
						<Route path="/secundaria/reglamento" element={<CABA_SVP_SECU_Regulation />} />
						<Route path="/profesores/asistencia" element={<TeachersAssistance />} />
						<Route path="/profesor" element={<TeacherSite/>} />
						<Route path="/configuracion/informe-psicopedagogico" element={<PsychopedagogicTags/>} />
						<Route path="/matriculacion" element={<InscriptionNextYear/>} />
						<Route path="/debug" element={<Debug/>} />
						<Route path="*" element={<Navigate to="/" replace />} />
					</>
				}
			</Routes>
		)
	}

	return (
		<div>
			<Router>
				<Row className="m-0">
					{isMaintenance == null ?
						<div className='justify-content-center'>
							<Loading animation={'border'} classExtra='loading-100' />
						</div>
						:
						isMaintenance != true
							? <ProcessRoutes />
							: <Routes>
								<Route path="/mantenimiento" element={<Maintenance />} />
								<Route path="/*" element={<Navigate to="/mantenimiento" replace />} />
							</Routes>
					}
				</Row>
			</Router>
		</div>
	);
}