import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar';
import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faClipboardList, faClipboardUser, faEllipsisV, faFile, faFileCircleExclamation, faFolderOpen, faPen, faPenFancy, faPlus, faTimes, faTrash, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { Badge, Button, Card, Col, Dropdown, DropdownMenu, Form, Modal, Row, Table, Spinner, ToggleButton, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { getFamilyMemberId, getFamilyMemberToken, getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { useLocation } from "react-router-dom";
import Loading from '../../general-components/Loading';
import { DEL, GET, POST, PUT } from '../../../controllers/endpoints.controller';
import NotFound from '../../general-components/NotFound';
import InfoField from '../../general-components/InfoField';
import { formatNameV2 } from '../../../tools/tools';

function GradesByStudent() {
    const PAGE_TITLE = "Calificaciones - Registro de valoración académica";
    const PAGE_ICON = faPen;
    const PREVIOUS_PAGE = "";
    const PREVIOUS_URL = "";

    const location = useLocation();
    const courseId = location.state ? location.state.course : 'Curso';

    const [myRole, setMyRole] = useState(getUserRole());
    const [userToken, setUserToken] = useState(myRole.includes("Familia") || myRole.includes("Padre-Madre-Tutor") ? getFamilyMemberToken() : getUserToken());

    let keys = 0;
    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
        //getClassrooms();
        getTestTypes()
        getPeriods();
    }, []);

    // Tabla
    const [amountTest, setAmountTest] = useState(null); //guarda cant de tests por peiriodo
    const [verifierLength, setVerifierLength] = useState(null) //guarda bool por id del periodo, si es true = necesita +cols, si es false no
    const [loadedTable, setLoadedTable] = useState(true);

    const completer = (amount, header = false, pid, period) => {
        const COLS_TO_COMPLETE = /* 7 */ maxColumnsPeriod[pid];
        let am = amount;
        period.tests.map((t, i) => {
            if(t.grades.length == 0){
                amount--;
            }
        })
        let arrayTd = []
        for (let i = amount; i <= COLS_TO_COMPLETE; i++) {
            arrayTd.push(
                <td key={++keys} className='pb-0 pt-1 ps-0 pe-0' style={{ width: 70 }} >
                    <Row className={`ps-2 pe-2 ${header == true ? 'mh-40' : 'tableRows'}`} style={{ height: '100px', backgroundColor: '#F0F0F0' }}>
                        <Col className='align-center-vertically-v2'>
                            <div className='m-0' style={{ textAlign: 'center' }}>
                            </div>
                        </Col>
                    </Row>
                </td>
            )
        }
        return arrayTd;
    }



    const passingGrade = 6;

    const [periods, setPeriods] = useState([]);
    const [students, setStudents] = useState([]);
    const [gradesPeriods, setGradesPeriods] = useState([]);

    const [grades, setGrades] = useState(null);

    useEffect(() => {
        setupTable(gradesPeriods);
    }, [gradesPeriods])

    useEffect(() => {
        if (grades != null) {
            let maxs = {};
            console.log("máximos")
            grades.map((g, i) => {
                g.periods && g.periods.map((p, j) => {
                    maxs[p.periodId] = i == 0 ? p.tests.length : maxs[p.periodId] > p.tests.length ? maxs[p.periodId] : p.tests.length;
                    maxs[p.periodId] = maxs[p.periodId] < 8 ? 8 : maxs[p.periodId];
                })
            })
            setMaxColumnsPeriod(maxs);
            console.log(maxs)
        }
    }, [grades])

    const [selectedPeriodGeneralOption, setSelectedPeriodGeneralOption] = useState('Período');
    const [selectedPeriodGeneralId, setSelectedPeriodGeneralId] = useState(null);
    const [maxColumnsPeriod, setMaxColumnsPeriod] = useState({});

    const handleSelectPeriodGeneral = (eventKey) => {
        setSelectedPeriodGeneralOption(periods[eventKey].name);
        setSelectedPeriodGeneralId(periods[eventKey].id);
        //getAcademicAssessmentRecord(periods[eventKey].id, selectedClassId, false)
        getAcademicAssessmentRecord(periods[eventKey].id, getFamilyMemberId())
        getBreaches(periods[eventKey].id, getFamilyMemberId());
    };

    const [selectedTestTypeIsNumerical, setSelectedTestTypeIsNumerical] = useState(null);
    const [selectedEvaluativeGrades, setSelectedEvaluativeGrades] = useState([]);
    const [selectedNumericalGrades, setSelectedNumericalGrades] = useState([]);

    const getAcademicAssessmentRecord = async function (pgid, userId) {
        setLoadedTable(false)
        const req = await GET(`/grades/academicAssessmentRecord/byUserId&PeriodGroupId/${userId}/${pgid}`, userToken);
        //const req = await GET(`/grades/academicAssessmentRecord/byPeriodId&ClassId/${pgid}/${cid}/${avatar}`, userToken;
        const res = await req.json();
        if (req.status === 200) {
            console.log(res)
            setGrades(res)
            setLoadedTable(true)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const setupTable = (pe) => {
        if (pe.length > 1) {
            let endPeriodDate;
            let amTest = {};
            let leTest = {};
            for (let p of pe) {

                amTest[p.id] = p.Tests.length;

                if (p.Tests.length < 5) {
                    leTest[p.id] = true;
                } else {
                    leTest[p.id] = false;
                }
            }
            setAmountTest(amTest);
            setVerifierLength(leTest);
        } else {
            let amTest = {};
            let leTest = {};
            for (let p of pe) {
                amTest[p.id] = p.Tests.length;

                if (p.Tests.length < 7) {
                    leTest[p.id] = true;
                } else {
                    leTest[p.id] = false;
                }
            }
            setAmountTest(amTest);
            setVerifierLength(leTest);
        }
    }

    const getPeriods = async function () {
        //const req = await GET(`/grades/periods/noGrouped`, userToken;
        const req = await GET(`/grades/periods/groups/bySchoolYear/${2024}`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPeriods(res);
            setSelectedPeriodGeneralOption(res[0].name);
            setSelectedPeriodGeneralId(res[0].id);
            getAcademicAssessmentRecord(res[0].id, getFamilyMemberId())
            getBreaches(res[0].id, getFamilyMemberId());
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const GradeValue = ({ g }) => {
        if (g != undefined) {
            if (g.numerical != null) {
                //Numerical grade
                if (g.numerical == "ND") {
                    return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 50, backgroundColor: 'white' }}>--</Badge>)
                } else if (g.numerical >= passingGrade) {
                    //Approved
                    return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 50, backgroundColor: 'green' }}>{g.numerical}</Badge>)
                } else {
                    return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 50, backgroundColor: 'red' }}>{g.numerical}</Badge>)
                }
            } else if (g.EvaluativeGrade) {
                //Evaluative grade
                if (g.EvaluativeGrade.approved) {
                    return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 50, backgroundColor: 'green' }}>{g.EvaluativeGrade.value}</Badge>)
                } else {
                    return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 50, backgroundColor: 'red' }}>{g.EvaluativeGrade.value}</Badge>)
                }
            }
        } else {
            return '';
        }
    }

    const [studentsBreaches, setStudentsBreaches] = useState(null);
    const getBreaches = async function (gpid, userId) {
        const req = await GET(`/grades/missingAssignments/getByUserIdAndPeriodGroup/${userId}/${gpid}`, userToken);
        const res = await req.json();
        if (req.status === 200) {
            console.log(res);
            setStudentsBreaches(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    function getbreachesByStudentId(userId) {
        return studentsBreaches.filter(obj => obj.userId === userId);
    }


    function getbreachesByClassId(classId) {
        return studentsBreaches.filter(obj => obj.id === classId);
    }

    const [showStudentBreachesModal, setShowStudentBreachesModal] = useState(false);
    const [classBreaches, setClassBreaches] = useState(null);
    const [breachesByStudent, setBreachesByStudent] = useState(null)
    const handleShowStudentBreachesModal = (e, a) => {
        setBreachesByStudent(e[0])
        setClassBreaches(a)
        setShowStudentBreachesModal(true);
    };
    const handleCloseStudentBreachesModal = () => {
        setShowStudentBreachesModal(false);
    };

    const[testTypesText, setTestTypesText] = useState(null);
    const getTestTypes = async function () {
        const req = await GET(`/grades/testTypes`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            let txt = "";
            for (let i = 0; i < res.length; i++) {
                if (i == (res.length - 1)) {
                    txt += `${res[i].value}: ${res[i].name}`
                } else {
                    txt += `${res[i].value}: ${res[i].name} | `
                }
            }
            setTestTypesText(txt);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content fs-14">
                <div className='d-block d-lg-none' style={{ height: '70px' }}> </div>

                <Row className='toolbar m-0'>
                    <Col style={{ display: 'flex', flexDirection: 'row !important', alignItems: 'center' }}>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}
                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>

                    </Col>
                </Row>

                <div className='d-block d-lg-none mt-3'></div>
                <div className='d-none d-lg-block mt-4'></div>
                <Row className='' style={{ rowGap: '8px' }}>
                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-250">
                                    {selectedPeriodGeneralOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedPeriodGeneralOption}>
                                    {periods.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectPeriodGeneral(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <p className='fs-11 mb-0 mt-3'>{testTypesText}</p>
                </Row>

                <Card className='cards mt-3 fs-14 h-100-3 mobile-cards'>
                    {!loadedTable ?
                        <div className='justify-content-center'>
                            {selectedPeriodGeneralId != null ?
                                <Loading animation={'border'} classExtra='loading-100' />
                                :
                                <NotFound classExtra="mt-5" iconFa={faFileCircleExclamation} text='Seleccioná un curso, una materia y un período' />
                            }
                        </div> :

                        <div className='slideTable' style={{ borderRadius: '10px' }}>

                            <table className='br-15 table m-0' style={{ borderColor: 'white', borderSpacing: '0', borderCollapse: 'separate' }}>
                                <thead className='sticky-row headerBlue' style={{ color: 'white' }}>
                                    <tr className='headerContainer'>

                                        <td className='align-itmes-center sticky-column' rowSpan="4" >
                                            <div style={{ textAlign: 'left' }} className='ps-1'>
                                                Asignatura
                                            </div>

                                        </td>
                                    </tr>
                                    <tr className='headerContainer'>
                                        <td className='align-itmes-center' rowSpan="3" colSpan={1}>Incum.</td>
                                    </tr>
                                    <tr className='headerContainer'>
                                        {grades && grades[0].periods && grades[0].periods.map((gp, i) =>
                                            <td key={++keys} colSpan={maxColumnsPeriod[gp.periodId] + 1}>{gp.name}</td>
                                        )}
                                    </tr>

                                </thead>

                                <tbody>

                                    {grades && grades.map((st, i) => (
                                        <tr key={++keys} className='' style={{ border: 'white' }}>

                                            <td className='sticky-column pb-0 pt-1 ps-0 pe-0' >
                                                <div className='d-none d-lg-block'>
                                                    <Row className='' style={{ minWidth: '200px', height: '100px', backgroundColor: '#F0F0F0', borderRadius: '8px 0 0 8px' }}>
                                                        <Col className='align-center-vertically-v2'>
                                                            <div style={{ textAlign: 'left' }}>
                                                                {st.name}
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className='d-block d-lg-none fs-12'>
                                                    <Row className='' style={{ width:'90px', height: '100px', backgroundColor: '#F0F0F0' }}>
                                                        <Col className='align-center-vertically-v2'>
                                                            <div style={{ textAlign: 'left' }}>
                                                                {st.name}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            </td>

                                            <td className='pb-0 pt-1 ps-0 pe-0'>
                                                <Row className='' style={{ height: '100px', backgroundColor: '#F0F0F0' }}>
                                                    <Col className='align-center-vertically-v2'>
                                                        {studentsBreaches != null && getbreachesByClassId(st.id).length > 0 &&
                                                            <div className='align-center-vertically' style={{ justifyContent: 'center' }}>
                                                                <Badge bg='warning' className='statusBadge fs-14 mt-1 mb-1' onClick={() => handleShowStudentBreachesModal(getbreachesByClassId(st.id), st.name)} style={{ height: 36, width: 50, backgroundColor: 'white', cursor: 'pointer', color: 'black' }}>
                                                                    {getbreachesByClassId(st.id)[0].totalMissingAssingment}
                                                                </Badge>
                                                            </div>}
                                                    </Col>
                                                </Row>
                                            </td>
                                            {st.periods && st.periods.map((p, ind) => (<>
                                                {p.tests && p.tests.map((t, k) => t.grades.length > 0 && (
                                                    <td className='pb-0 pt-1 ps-0 pe-0' style={{ width: 70, paddingLeft: '1px !important', paddingRight: '1px !important' }}>
                                                        <Row style={{ height: '100px', backgroundColor: '#F0F0F0' }}>
                                                            <Col className='align-center-vertically-v2 pb-1'>
                                                                <div className='m-0 p-2' style={{ textAlign: 'center', backgroundColor: 'white', borderRadius: '10px' }}>
                                                                    <p className='mb-0 fs-11'>{moment(t.date).format('DD/MM')}</p>
                                                                    <p className='mb-0'>{t.TestType.value}</p>
                                                                    <GradeValue g={t.grades[0]} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </td>) 
                                                )}
                                                {p.tests && completer(p.tests.length, false, p.periodId, p).map((r, i) => r)}
                                            </>))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                </Card>
            </div >

            <Modal size={'xs'} show={showStudentBreachesModal} onHide={handleCloseStudentBreachesModal} centered className='assitanceModal modalContainer' backdrop="static" style={{ zIndex: 1049 }}>
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faClipboardList} style={{ minWidht: '20px' }} /><Modal.Title>Incumplimientos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className='ps-0 pe-0'>
                            <InfoField content={<div style={{ display: 'flex', alignItems: 'center' }}>
                                <p className='classesTime m-0'><p className='m-0'>{classBreaches != null && classBreaches}</p></p>
                            </div>} title='Asignatura' />
                        </Col>
                    </Row>
                    <Row className='p-2 mt-3' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                        <Col md={3} className='align-center-vertically-v2'>
                            <div style={{ textAlign: 'left' }}>
                                Fecha
                            </div>
                        </Col>
                        <Col md={9} className='align-center-vertically-v2'>
                            <div style={{ textAlign: 'left' }}>
                                    Descripción
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        {breachesByStudent != null && breachesByStudent.missingAssignment && breachesByStudent.missingAssignment.map((r, i) => (
                            <Row className='mb-1 pt-1 pb-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8, minHeight: '40px' }}>
                                <Col md={3} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'left' }}>
                                        {moment(r.date).format("DD/MM/YYYY")}
                                    </div>
                                </Col>
                                <Col md={9} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'left' }}>
                                        {r.description}
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseStudentBreachesModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>
                    {/* <Button variant="primary" className='fs-14' disabled={selectedDateDayBreaches == null}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button> */}
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default GradesByStudent